const isClient = typeof window !== 'undefined';
const disableMetrics = /(disable-metrics=)/.exec(window.location.search);

if (isClient) {
  if ('performance' in window) {
    if ('PerformanceObserver' in window) {
      if (disableMetrics) {
        console.info('Metrics disabled. Custom metrics not initialised.');
      } else {
        const load = () =>
          import('./jl-metrics.js').then(({ init, exposeMetrics }) => {
            init();
            window.__jlMetrics = { exposeMetrics };
          });

        if (window.requestIdleCallback) {
          window.requestIdleCallback(load);
        } else {
          load();
        }
      }
    }
  }
}
